export function convertStorefrontIdToShopifyId(id: string): string {
  return atob(id)!.split("/").pop()!;
}

export const universalBtoa = (str: string) => {
  try {
    return btoa(str);
  } catch (err) {
    return Buffer.from(str).toString("base64");
  }
};
