export const colorMap: { [key: string]: any } = {
  azzurro: "#B4E1FA",
  "azzurro pastello": "#59BFDE",
  arancione: "#fd7e00",
  beige: "#E1C699",
  bianco: "#F9FAFB",
  blu: "#084E8A",
  champagne: "#fad6a5",
  fucsia: "#ba3c8d",
  giallo: "#EEC200",
  grigio: "#DFE3E8",
  marrone: "#8A6116",
  nero: "#222222",
  oro: "#d4af37",
  "oro bianco": "#c0c0c0",
  "oro giallo": "#d4af37",
  "oro rosa": "#f0ceb9",
  platino: "#c0c0c0",
  perla: "#eae6ca",
  pesca: "#ffe5b4",
  rosa: "#ffd1dc",
  "rosa pastello": "#EEB3BC",
  "rosa/nero": ["#ffd1dc ", "#222222"],
  rosso: "#DE3618",
  verde: "#50B83C",
  "verde chiaro": "#BBE5B3",
  "verde scuro": "#166B27",
  sabbia: "#A07C41",
  "giallo fluo": "#C9DB03",
  "fucsia fluo": "#F400A1",
  "verde fluo": "#83F52C",
  arancio: "#FC9D03",
  turchese: "#40E0D0",
  rodio: "#C0C0C0",
  bordeaux: "#7B002C",
  "rosso acceso": "#F70D1A",
  corallo: "#FF4040",
  acquamarina: "#66CCAA",
  smeraldo: "#509076",
  rutenio: "#333",
  glicine: "#e8ddfd",
  multicolor: [
    "#3fa4d9",
    "#33beb8",
    "#b2c224",
    "#fecc31",
    "#f9a327",
    "#f6621e",
    "#db3738",
    "#ee647a",
    "#a463d8",
  ],
  "rosso mattone": "#CE5757",
  amarena: "#BC4B9F",
  viola: "#7151B0",
  menta: "#AAF0D1",
};
