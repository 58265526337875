import { ShopifyProduct, ShopifyProductVariant } from "graphql-typings";
import { isEqual, uniqWith } from "lodash";
import { useCallback, useState } from "react";
import useProductOptions from "./useProductOptions";

interface IOption {
  [key: string]: { name: string; displayValue: string };
}

export type TCombination = { [key: string]: string };

interface ProductOptionValue {
  shopifyValue: string;
  value: string;
}

export function useProductColor(shopifyProduct: ShopifyProduct) {
  const variants = shopifyProduct.variants as ShopifyProductVariant[];

  // Initializers
  const initSelectedVariant = () => {
    if (!variants || variants.length === 0) {
      throw new Error("Product has no variants");
    }
    return variants && variants[0];
  };

  const initSelectedOptions = () => {
    if (!variants || variants.length === 0) {
      throw new Error("Product has no variants");
    }
    return optionsToObj(variants[0]);
  };

  // State variables
  const [selectedVariant, setSelectedVariant] = useState(initSelectedVariant());
  const [selectedOptions, setSelectedOptions] = useState(initSelectedOptions());

  const productOptions = useProductOptions();

  /**
   * Reconcile the selection coming from a variant selector
   */
  const reconcileColorSelector = (option: IOption) => {
    const keys = Object.keys(option);
    const optionObj = keys.reduce(
      (acc, key) => ({ ...acc, [key]: option[key].name }),
      {} as { [key: string]: string }
    );
    const newOptions = { ...selectedOptions, ...optionObj };
    setSelectedOptions(newOptions);
    const foundVariant = findFromOptions(newOptions, variants);
    // @ts-ignore
    setSelectedVariant(foundVariant);
  };

  /**
   * Compute color options from a product
   */
  const extractColorCombinations = useCallback((product: ShopifyProduct) => {
    const variants = product.variants as ShopifyProductVariant[];
    const variantNames = extractOptionNamesOrdered(variants).filter(
      (optionName) => {
        const option = productOptions[optionName];
        return option && option.selectorType === "colore";
      }
    );
    if (variantNames.length > 0) {
      const variantCombinations = extractCombinations(variants).map(
        (combination) => {
          const obj: typeof combination = {};
          const keys = Object.keys(combination);
          keys.forEach((key) => {
            if (variantNames.includes(key)) {
              obj[key] = combination[key];
            }
          });
          return obj;
        }
      );
      return uniqWith(
        variantCombinations
          .map((combination) => {
            const obj: IOption = {};
            const keys = Object.keys(combination);
            keys.forEach((key) => {
              const option = productOptions[key];
              obj[key] = preprocessVariantOption(
                combination[key],
                option && option.values
              );
            });
            return {
              ...obj,
            };
          })
          .filter((obj) => Object.keys(obj).length !== 0),
        isEqual
      );
    }
    return [];
  }, []);

  /**
   * Produce an array of the available variant options,
   * ordered by priority
   */
  const extractOptionNamesOrdered = (variants: ShopifyProductVariant[]) =>
    variants.reduce((acc: string[], variant: any) => {
      variant.selectedOptions.forEach((option: any) => {
        const name = option.name;
        if (!acc.includes(name)) {
          acc.push(name);
        }
      });
      return acc;
    }, []);

  return {
    selectedVariant,
    selectedOptions,
    reconcileColorSelector,
    extractColorCombinations,
  };
}

/**
 * Enrich shopify variant name with their display value and eventually color
 */
const preprocessVariantOption = (
  shopifyOptionValue: string,
  productOptionValues?: ProductOptionValue[]
) => {
  const optionValue = findOptionValue(shopifyOptionValue, productOptionValues);
  return {
    name: shopifyOptionValue,
    displayValue: (optionValue && optionValue.value) || shopifyOptionValue,
  };
};

/**
 * Find the localized variant option for each shopify option value
 */
const findOptionValue = (
  shopifyOptionValue: string,
  productOptionValues?: ProductOptionValue[]
) => {
  return (
    productOptionValues &&
    productOptionValues.find(
      (option) => option!.shopifyValue === shopifyOptionValue
    )
  );
};

/**
 * Find a variant in a variant list starting from an options object
 */
const findFromOptions = (
  options: TCombination,
  variants: ShopifyProductVariant[]
) => variants.find((variant) => isEqual(options, optionsToObj(variant)));

/**
 * Transform variant options array to object
 */
const optionsToObj = (variant: ShopifyProductVariant) =>
  variant.selectedOptions!.reduce(
    (acc, option: any) => ({ ...acc, [option.name]: option.value }),
    {} as TCombination
  );

/**
 * Extract combinations
 */
const extractCombinations = (variants: ShopifyProductVariant[]) =>
  variants.reduce((acc, variant) => {
    let combination: TCombination = {};
    variant.selectedOptions!.forEach((option: any) => {
      combination[option.name] = option.value;
    });
    return [...acc, combination];
  }, [] as TCombination[]);
