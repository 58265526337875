import Link from "components/Link";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { ShopifyMediaImage, ShopifyProduct } from "graphql-typings";
import useHover from "hooks/useHover";
import { FieldLocale, useLocale } from "hooks/useLocale";
import { useProductColor } from "hooks/useProductColor";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ILocalizedProduct } from "types";
import { colorMap } from "utils/common/colors";
import formatMoney from "utils/common/formatMoney";
import { getLocalizedValue } from "utils/common/locale";
import { getFirstMediaImage, mapMediaToImages } from "utils/image";
import SelectColorOptions from "./SelectColorOptions";

interface Props {
	product: ILocalizedProduct;
	className?: string;
	behaviour?: "link" | "button";
	onClick?: (id: string) => void;
}

interface StaticQuery {
	datoCmsGeneral: {
		_allDiscountedProductTextLocales: FieldLocale<string>[];
	};
}

const ProductCard: React.SFC<Props> = ({
	product,
	className,
	behaviour = "link",
	onClick,
}) => {
	const { locale, defaultLocale } = useLocale();
	const { selectedVariant, extractColorCombinations, reconcileColorSelector } =
		useProductColor(product.shopify as ShopifyProduct);
	const { datoCmsGeneral } = useStaticQuery<StaticQuery>(staticQuery);
	const discountedProductText = getLocalizedValue(
		datoCmsGeneral._allDiscountedProductTextLocales,
		locale,
	);

	const [image, setImage] = useState(
		getFirstMediaImage(selectedVariant.media) ||
			getFirstMediaImage(product.shopify.media),
	);

	const { ref, value: isHoveringImage } = useHover();

	// Effects
	useEffect(() => {
		if (selectedVariant && selectedVariant.media.length > 0) {
			const image = getFirstMediaImage(selectedVariant.media);
			if (image) {
				setImage(image);
			}
		}
	}, [selectedVariant]);

	/**
	 * Transform product option combinations to color array to be used in
	 * selectColorOptions
	 */
	const combinationsToColors = (colorCombinations: any[]) =>
		colorCombinations.reduce((acc: any[], elem: any, index: number) => {
			const keys = Object.keys(elem);
			const color = keys.reduce(
				(acc2: any, key: any) => ({
					...acc2,
					hex: [...acc2.hex, colorMap[elem[key].name.toLowerCase()]],
					label: acc2.label
						? `${acc2.label}/${elem[key].displayValue}`
						: elem[key].displayValue,
				}),
				{ value: index, hex: [], label: "" },
			);

			return [...acc, color];
		}, []);

	/**
	 * Retrieve the product combination associated to the corresponding color
	 */
	const colorToCombination = (value: string) => {
		const index = Number(value);
		return colorCombinations[index];
	};

	const colorCombinations = extractColorCombinations(
		product.shopify as ShopifyProduct,
	);
	const colors = combinationsToColors(colorCombinations);
	const hoverImage = mapMediaToImages(product.shopify.media).find(
		(image) => image.altText === "hover",
	);
	const productPath = `${locale === defaultLocale ? `` : `/${locale}`}/${
		product.dato.category!.handle
	}/${product.dato.handle}`;

	const isDiscounted =
		selectedVariant.compareAtPrice &&
		selectedVariant.compareAtPrice !== selectedVariant.price &&
		selectedVariant.price > 0;
	const discountPercentage = isDiscounted
		? Math.round(
				((selectedVariant.price - selectedVariant.compareAtPrice!) /
					selectedVariant.compareAtPrice!) *
					100,
			)
		: null;

	return (
		<Container className={className}>
			<LabelsContainer>
				{/* {isDiscounted && discountedProductText && (
          <DiscountLabel>{discountedProductText}</DiscountLabel>
        )} */}
				{isDiscounted && <DiscountLabel>{discountPercentage}%</DiscountLabel>}
				{product.dato.featured && <FeaturedLabel>♥︎</FeaturedLabel>}
			</LabelsContainer>
			<Behaviour
				kind={behaviour}
				url={productPath}
				onClick={
					onClick
						? () => onClick(selectedVariant.storefrontId as string)
						: undefined
				}
			>
				<ImageContainer ref={ref}>
					{image && image.gatsbyImageData && (
						<GatsbyImageWrapper visible={!isHoveringImage || !hoverImage}>
							<Image image={image.gatsbyImageData} alt={product.dato.title} />
						</GatsbyImageWrapper>
					)}
					{hoverImage && (
						<TraditionalImage
							src={hoverImage.originalSrc!}
							alt={product.dato.title}
							visible={isHoveringImage && !!hoverImage}
						/>
					)}
					<FrameTop />
					<FrameBottom />
				</ImageContainer>
				<Title>{product.dato.title}</Title>
				<PriceContainer>
					{isDiscounted && (
						<CompareAtPrice>
							{formatMoney(
								selectedVariant.compareAtPrice! * 100,
								"€{{amount}}",
							)}
						</CompareAtPrice>
					)}
					{selectedVariant.price > 0 && (
						<Price isDiscounted={Boolean(isDiscounted)}>
							{formatMoney(selectedVariant.price * 100, "€{{amount}}")}
						</Price>
					)}
				</PriceContainer>
			</Behaviour>
			{colorCombinations && colorCombinations.length > 1 && (
				<SelectColorOptions
					initialValue={colors[0].value}
					name={product.shopify.storefrontId as string}
					visibleOptions={4}
					options={colors}
					onChange={(value: string) =>
						reconcileColorSelector(colorToCombination(value))
					}
				/>
			)}
		</Container>
	);
};

const staticQuery = graphql`
  {
    datoCmsGeneral {
      _allDiscountedProductTextLocales {
        locale
        value
      }
    }
  }
`;

interface ILinkBehaviour {
	kind: "link";
	url?: string;
}

interface IButtonBehaviour {
	kind: "button";
	onClick?: () => void;
}

function Behaviour(
	props: React.PropsWithChildren<ILinkBehaviour | IButtonBehaviour>,
) {
	switch (props.kind) {
		case "button":
			return <div onClick={props.onClick}>{props.children}</div>;
		default:
			if (!props.url) {
				console.warn(
					"Product card has link behaviour but no link has been defined",
				);
			}
			return <LinkToProduct to={props.url!}>{props.children}</LinkToProduct>;
	}
}

const Container = styled.div`
  position: relative;
  padding: 10px 10px 30px 10px;
  box-sizing: border-box;
`;

const LabelsContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  display: flex;
`;

const DiscountLabel = styled.div`
  padding: 0 10px 1px 10px;
  height: 26px;
  background-color: #d24017;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeaturedLabel = styled.div`
  padding-top: 1px;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.main};
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  box-shadow: 0px 0px 6px 0px rgba(151, 123, 43, 0.7);
`;

const FrameTop = styled.div``;

const FrameBottom = styled.div``;

const ImageContainer = styled.div`
  position: relative;
  ${FrameTop}::before, ${FrameTop}::after,
  ${FrameBottom}::before, ${FrameBottom}::after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.background2};
    transition: all 0.5s;
  }
  ${FrameTop}::before,${FrameBottom}::before {
    left: 0;
    top: 0;
  }
  ${FrameTop}::after,${FrameBottom}::after {
    right: 0;
    bottom: 0;
  }
  ${FrameTop}::before,${FrameTop}::after {
    height: 5px;
    width: 0;
  }
  ${FrameBottom}::before, ${FrameBottom}::after {
    width: 5px;
    height: 0;
  }
  &:hover ${FrameTop}::before, &:hover ${FrameTop}::after {
    width: 100%;
  }
  &:hover ${FrameBottom}::before, &:hover ${FrameBottom}::after {
    height: 100%;
  }
`;

const GatsbyImageWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }: { visible: boolean }) =>
		visible ? "block !important" : "none !important"};
`;

const Image = styled(GatsbyImage)`
  max-width: 100%;
  max-height: 100%;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const TraditionalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: ${({ visible }: { visible: boolean }) =>
		visible ? "block !important" : "none !important"};
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.08em;
  color: #706f6f;
  line-height: 1.5em;
  padding-top: 10px;
`;

const LinkToProduct = styled(Link)`
  text-decoration: none;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CompareAtPrice = styled.span`
  font-size: 14px;
  letter-spacing: 0.08em;
  padding-right: 10px;
  text-decoration: line-through;
  color: #333;
  font-weight: 600;
  padding-top: 5px;
`;

const Price = styled.div<{ isDiscounted?: boolean }>`
  color: #000;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-align: center;
  font-weight: 600;
  padding-top: 5px;
  color: #d24017;
  ${({ isDiscounted }) => !isDiscounted && `color: #333;`};
`;

export default styled(ProductCard)``;
