import React, { useState } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider
} from "react-carousel";
import styled from "styled-components";
import CircleArrowLeft from "./icons/CircleArrowLeft";
import CircleArrowRight from "./icons/CircleArrowRight";

export interface ColorObject {
  value: string;
  hex: string[];
  label?: string;
}

export type Color = string | ColorObject;

export type Size = "small" | "normal";

interface Props {
  name: string;
  initialValue?: string;
  value?: string;
  options: Color[];
  visibleOptions?: number;
  size?: Size;
  onChange?: (value: string) => void;
}

const SelectColorOptions: React.SFC<Props> = ({
  name,
  initialValue,
  value,
  options,
  visibleOptions = 3,
  size = "normal",
  onChange = () => {}
}) => {
  const [internalValue, setInternalValue] = useState(initialValue);

  const isControlled = () => value !== undefined;
  const getValue = () => value || internalValue;

  return (
    <SelectContainer
      numberOfSlides={options.length}
      visibleNumberOfSlides={visibleOptions}
    >
      {options.map((option, index) => (
        <ColorSlide
          key={isColorObject(option) ? option.value : option}
          enabled={options.length > visibleOptions}
          id={isColorObject(option) ? option.value : option}
          index={index}
        >
          <ColorInput
            name={name}
            option={option}
            checked={
              isColorObject(option)
                ? option.value === getValue()
                : option === getValue()
            }
            size={size}
            onChange={() => {
              onChange(isColorObject(option) ? option.value : option);
              if (!isControlled()) {
                setInternalValue(isColorObject(option) ? option.value : option);
              }
            }}
          />
        </ColorSlide>
      ))}
    </SelectContainer>
  );
};

interface SelectContainerProps {
  numberOfSlides: number;
  visibleNumberOfSlides: number;
}

function SelectContainer({
  children,
  numberOfSlides,
  visibleNumberOfSlides
}: React.PropsWithChildren<SelectContainerProps>) {
  return numberOfSlides > visibleNumberOfSlides ? (
    <Container>
      <CarouselProvider
        totalSlides={numberOfSlides}
        visibleSlides={visibleNumberOfSlides}
      >
        <Slider>{children}</Slider>
        <ButtonBack>
          <CircleArrowLeft />
        </ButtonBack>
        <ButtonNext>
          <CircleArrowRight />
        </ButtonNext>
      </CarouselProvider>
    </Container>
  ) : (
    <Container>{children}</Container>
  );
}

interface ColorSlideProps {
  enabled: boolean;
  index: number;
  id: string;
}

function ColorSlide({
  children,
  enabled,
  index,
  id
}: React.PropsWithChildren<ColorSlideProps>) {
  return enabled ? (
    <Slide key={id} index={index}>
      {children}
    </Slide>
  ) : (
    <>{children}</>
  );
}

interface ColorInputProps {
  option: Color;
  name: string;
  checked: boolean;
  size: Size;
  onChange: () => void;
}

function ColorInput({
  option,
  name,
  checked,
  size,
  onChange
}: ColorInputProps) {
  const value = isColorObject(option) ? option.value : option.replace("#", "");
  const id = `${name}-${value}`;
  const accessibilityText = isColorObject(option) ? option.label : option;
  const colors = isColorObject(option) ? option.hex : [option];
  return (
    <ColorContainer onClick={e => e.stopPropagation()} size={size}>
      <ColorRadioButton
        id={id}
        type="radio"
        name={name}
        checked={checked}
        size={size}
        onChange={onChange}
      />
      <ColorLabel htmlFor={id}>
        {accessibilityText && (
          <AccessibilityText>{accessibilityText}</AccessibilityText>
        )}
        <Color colors={colors} size={size} />
      </ColorLabel>
    </ColorContainer>
  );
}

function isColorObject(arg: any): arg is ColorObject {
  return arg.value !== undefined;
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 160px;
  height: 40px;
  display: block;
  text-align: center;
  margin-top: 6px;
  button {
    background-color: transparent;
    height: 16px;
    width: 16px;
    border: none;
    transition: 0.3s all;
    padding: 8px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      height: 100%;
      width: 100%;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const margins = {
  small: 8,
  normal: 12
};

const ColorContainer = styled.div<{ size: Size }>`
  margin: ${props => margins[props.size]}px;
  display: inline-block;
  &.hidden {
    display: none;
  }
`;

const selectedBorderOffset = {
  small: 4,
  normal: 6
};
const Color = styled.div<{ size: Size; colors: string[] }>`
  ${({ colors }) => {
    const percentage = 100 / colors.length;
    let configuration = "";
    for (let i = 0; i < colors.length; i++) {
      configuration += `${colors[i]} ${percentage * i}%, ${
        colors[i]
      } ${percentage * (i + 1)}%${i + 1 < colors.length ? "," : ""}`;
    }
    return `background: linear-gradient(90deg, ${configuration});`;
  }};
  &::before {
    content: "";
    position: absolute;
    top: -${props => selectedBorderOffset[props.size]}px;
    left: -${props => selectedBorderOffset[props.size]}px;
    bottom: -${props => selectedBorderOffset[props.size]}px;
    right: -${props => selectedBorderOffset[props.size]}px;
    border: 2px solid rgba(213, 213, 213, 0);
    border-radius: 100%;
    transition: 0.4s all;
  }
`;

const sizes = {
  small: 12,
  normal: 16
};

const ColorRadioButton = styled.input<{ size: Size }>`
  display: none;

  &:checked ~ label {
    ${Color} {
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
      transform: scale(1, 1);
      &::before {
        border: 2px solid rgba(213, 213, 213, 1);
      }
    }
  }
  ~ label {
    cursor: pointer;
    ${Color} {
      display: block;
      height: ${props => sizes[props.size]}px;
      width: ${props => sizes[props.size]}px;
      border-radius: 100%;
      transition: transform 400ms;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
      position: relative;
      &:hover {
        transform: scale(1.2, 1.2);
      }
    }
  }
`;

const ColorLabel = styled.label``;

const AccessibilityText = styled.span`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
`;

export default SelectColorOptions;
